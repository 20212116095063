<template>
    <div class="page">
        <FirstFloor width="100%" />

        <div class="grey--text">
            Tap on the stations (<v-icon small>mdi-map-marker</v-icon>) for more details
        </div>

        <v-row no-gutters>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/first-floor">
                    FIRST FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/second-floor">
                    SECOND FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/third-floor">
                    THIRD FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="rentDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Rent</v-card-title>
                    Rent Suits and Tuxes in our Puerto Rico or Dominican Republic Stores. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="tailorRoomDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Clubman's Tailors Room</v-card-title>
                    Take a peek inside where our master tailors work their magic to create the perfect fit for our customers. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="readyToWearDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Ready to Wear</v-card-title>
                    <div class="subtitle">Shop your favorite looks from our RTW collections.</div>
                    Designed twice a year, Fall / Winter and Spring / Summer, by LFA design team. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="kioskDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Self Kiosk</v-card-title>
                    Build your looks and shop our collections in the easiest way possible through our self- kiosks. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="designerDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Designer's Collection Men</v-card-title>
                    Explore our latest Men's Ready-to-Wear designer's collection created with this season's styles and colors.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="designerDialogWomen">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Designer's Collection Women</v-card-title>
                    Explore our latest Women’s Ready-to-Wear designer’s collection created with this season’s styles and colors. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="tailorLoungeDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">1971 Speakeasy</v-card-title>
                    Step into our secret bar and lounge designed for our exclusive “custom made and couture” customers and vip's.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fittingDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Fitting Rooms</v-card-title>
                    Hosting 12 fitting rooms where clients shop and try on the garment pieces. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="clubmanDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Clubman by Leonardo</v-card-title>
                    Shop our sport line where you can find more casual clothing including loungewear, swimwear, jeans, knits, polos, sport coats and more. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="leonardosDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Leonardo Cordero Suria</v-card-title>
                    Meet our CEO and Head Designer, 3rd generation of Leonardo 5th Avenue and the man behind the House of Fashion. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="historyDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Historic Gallery</v-card-title>
                    <div class="subtitle">Walk through the legacy of Leonardo 5th Avenue.</div>
                    From its first store in New York City after WWII to where the fashion house stands today. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dtmDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Design to Measure</v-card-title>
                    Take a seat at our design tables and create with our stylists a unique design for your next special occasion.  Don't be afraid to play with our interactive software!
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="artGalleryDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Mural Art Gallery</v-card-title>
                    Walk through our latest iconic mural art gallery inspired by urban street art and the L5A lifestyle. By Humberto Olivieri. 
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FirstFloor from '../assets/first-floor.svg?inline'; // Note the `?inline` query string

export default {
    data: () => ({
        rentDialog          : false,
        tailorRoomDialog    : false,
        readyToWearDialog   : false,
        kioskDialog         : false,
        designerDialog      : false,
        designerDialogWomen : false,
        tailorLoungeDialog  : false,
        fittingDialog       : false,
        clubmanDialog       : false,
        leonardosDialog     : false,
        historyDialog       : false,
        dtmDialog           : false,
        artGalleryDialog    : false,
    }),
    mounted() {
        document.title = 'LFA | HOF - First Floor';
        setTimeout(() => {
            this.init();
        }, 500 );
    },
    methods: {
        init() {
            document.getElementById('rentPath').addEventListener('click', () => {
                this.rentDialog = true;
            });
            document.getElementById('tailorRoom').addEventListener('click', () => {
                this.tailorRoomDialog = true;
            });
            document.getElementById('Ready_to_Wear').addEventListener('click', () => {
                this.readyToWearDialog = true;
            });
            document.getElementById('selfKiosk').addEventListener('click', () => {
                this.kioskDialog = true;
            });
            document.getElementById('designersCollectionMen').addEventListener('click', () => {
                this.designerDialog = true;
            });
            document.getElementById('designersCollectionWomen').addEventListener('click', () => {
                this.designerDialogWomen = true;
            });
            document.getElementById('tailorLounge').addEventListener('click', () => {
                this.tailorLoungeDialog = true;
            });
            document.getElementById('fittingRooms').addEventListener('click', () => {
                this.fittingDialog = true;
            });
            document.getElementById('clubmanLogo').addEventListener('click', () => {
                this.clubmanDialog = true;
            });
            document.getElementById('leonardoLogo').addEventListener('click', () => {
                this.leonardosDialog = true;
            });
            document.getElementById('historicGallery').addEventListener('click', () => {
                this.historyDialog = true;
            });
            document.getElementById('designToMeasure').addEventListener('click', () => {
                this.dtmDialog = true;
            });
            document.getElementById('artGallery').addEventListener('click', () => {
                this.artGalleryDialog = true;
            });
        }
    },
    components: { FirstFloor }
}
</script>

<style lang="scss" scoped>
.page {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
svg text {
    font-size: 6px;
}
</style>